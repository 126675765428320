.review-container {
    background-color: #F2F2F2;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
}

.reviews-top {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.reviews-bottom {
    display: flex;
    /* width: 90%; */
    justify-content: space-evenly;
    align-items: center;
}

.review-img-container {
    height: 69px;
    width: 69px;
    border-radius: 100px;
}

.review-img-container>img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.review-heading {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    /* font-size: 2.35vw; */
    width: 50%;
    font-size: 40px;
    margin: 0px;

    color: #000000;
}

.review-text {
    font-family: 'IBM Plex Sans', sans-serif;
    color: #000000;
    margin: 0px;
    /* font-size: 16px; */
    font-size: 1vw;
}

/* .review-CTA {
    border: none;
    outline: none;
    background-color: #212626;
    color: #FFFFFF;
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 10px 30px 10px 30px;
    font-size: 16px;
    font-size: 1vw;
} */

.review-CTA {
    border: none;
    outline: none;
    background-color: #212626;
    color: #FFFFFF;
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 10px 20px 10px 20px;
    font-size: 16px;
    /* font-size: 1vw; */
    border-radius: 3px;
}

.review-CTA:hover {
    background-color: #707070;
    border-radius: 3px;
}

@media screen and (max-width:990px) {
    .review-heading {
        width: 100%;
    }

}

@media screen and (max-width:990px) {

    .review-heading {
        font-size: 37px;
    }

    .review-CTA {
        font-size: 16px;
    }

    .review-text {
        font-size: 16px;
    }

}

@media screen and (max-width:767px) {
    .review-container {
        padding-top: 40px;
    padding-bottom: 40px;

    }
}
