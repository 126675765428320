.input-text {
    font-size: 16px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: bold;
    /* font-size: 2.35vw; */
    color: #000000;
    margin: 0px 0px 10px 0px;
}

.input-field {
    border: 1px solid #E0E0E0;
    outline: none;
    width: 100%;
    padding: 10px 20px 10px 20px;
}