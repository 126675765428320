.steps-container {
    display: flex;
    justify-content: center;
    background-color: #F2F2F2;
}

.steps-subcontainer {
    /* background-color: red; */
    width: 80%;
    padding-top: 120px;
    padding-bottom: 120px;
}

.steps-heading {
    font-weight: 600;
    font-size: 40px;
    font-family: 'IBM Plex Sans', sans-serif;
    text-align: center;
    padding-bottom: 80px;

}

.steps-bottom {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 80px;

}

.dashed-border {
    position: absolute;
    border: 1px dashed #565D5D;
    width: 70%;
    top: -20px;
}

.rounder-corner1 {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 15%;
    background-color: #2C3434;
    top: -30px;
    border-radius: 100px;
}

.rounder-corner2 {
    position: absolute;
    height: 20px;
    width: 20px;
    top: -30px;
    border-radius: 100px;
    background-color: #2C3434;
}

.rounder-corner3 {
    position: absolute;
    /* height: 20px; */
    /* width: 20px; */
    top: -50px;
    right: 13%;
    border-radius: 100px;
    /* background-color: #2C3434; */
}

.steps-bottom-views {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}


.steps-bottom-img-view {
    height: 60px;
    width: 60px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black
}

.steps-bottom-img-view-img {
    object-fit: contain;
    height: 60%;
    width: 60%;
}

.steps-bottom-subheading {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: 21px;
}

.steps-bottom-para {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    text-align: center;
    color: #565D5D;
}

@media screen and (max-width:500px) {
    .steps-bottom-views {
        justify-content: flex-start;
        align-items: flex-start;

    }

    .steps-bottom-para {
        text-align: start;
    }

    

}