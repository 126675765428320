.signup-container {
    background-image: url('../../assests/signupbg.svg');
    height: 100vh;
    background-repeat: no-repeat;
    /* background-position: center; */
    /* background-size: 100vw; */
    background-size: cover;
    width: 100vw;

}


.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    /* margin-right: -50%; */
    transform: translate(-50%, -50%);
    height: 70%;
    width: 50%;
    overflow: scroll;
    background-color: #ffffff;
    border-radius: 5px;
}

.Modal2 {
    position: absolute;
    top: 30%;
    left: 50%;
    border-radius: 5px;
    right: auto;
    bottom: auto;
    /* margin-right: -50%; */
    transform: translate(-50%, -50%);
    /* height: 40%; */
    padding-bottom: 20px;
    /* display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; */
    width: 40%;
    height: 35%;

    overflow: scroll;
    background-color: #ffffff;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
}

.signup-shade {
    background-color: black;
    height: 100vh;
    opacity: 0.3;
}

.signup-noimage-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-noimage-img-view {
    height: 60px;
    width: 60px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid black */
}

.signup-noimage-img-view-img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.signup-noimage-subheading {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: 26px;
}

.signup-noimage-para {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    text-align: center;

}

.next-button {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: bold;
    background-color: #000000;
    width: 100%;
    outline: none;
    border: none;
    padding: 10px;
    color: white;
    opacity: 0.3;
}

.next-button:hover {
    opacity: 1;

}

hr.hr-line {
    border: 1px solid black;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@media screen and (max-width:950px) {
    .Modal {
        width: 70%;
    }

    .Modal2 {
        width: 70%;
        height: 40%;
    }
}

@media screen and (max-width:500px) {
    .Modal {
        width: 90%;
    }

    .Modal2 {
        width: 90%;

    }
}