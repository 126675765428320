/*  */
.benefits-left {
    background-image: url('../../../../assests/benefitsLeft.jpeg');
    height: 100%;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

/*  */
.benefits-right {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    height: 100%;
    padding-top: 140px;
    padding-bottom: 140px;
}

.benefits-heading {
    font-weight: 600;
    line-height: 50px;
    color: #2C3434;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 40px;
}

.benefits-sub-heading {
    font-weight: 600;
    color: #2C3434;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 21px;

}

.benefits-sub-text {
    /* font-weight: 600; */
    color: #565D5D;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;

}

@media screen and (max-width:1030px) {
    .benefits-left {
        height: 100%;
    }
}

@media screen and (max-width:767px) {
    .benefits-right {
        padding-top: 70px;
        padding-bottom: 70px;

    }
}



@media screen and (max-width:500px) {
    .benefits-heading {
        font-size: 30px;
        font-weight: bold;
    }
}