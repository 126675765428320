.faq-container {
    display: flex;
    justify-content: center;
}

.faqs {
    width: 60%;
}

.faq-heading {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    /* font-size: 2.35vw; */
    font-size: 47px;
    color: #000000;
}

.accordion_item {
    border-bottom: 1px solid #9f9f9f;
}


.button {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-size: 21px;
    background-color: #ffffff;
    color: #000000;
    text-align: left;
    display: flex;
    outline: none;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 8px;
    cursor: pointer;
    border: none;
}

.control {
    font-size: 20px;
}

.answer {
    background-color: #ffffff;
    padding: 0px 10px 10px 10px;
}

/* activate toggle */
.accordion_item.active .button {
    background-color: #ffffff;
}

.answer_wrapper {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
}

@media screen and (max-width:770px) {
    .faq-heading {
        font-size: 37px;
    }

}

@media screen and (max-width:675px) {
    .faqs {
        width: 90%;
    }

    .faq-heading {
        font-size: 37px;
        font-weight: 600;
    }
.button {
font-size: 12px;
}
}