.hero-left {
    background-color: #2C3434;
    height: 100vh;
}

.hero-left-content {
    /* background-color: red; */
    height: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-evenly; */
    /* align-items: flex-start; */
}

.hero-left-logo {
    object-fit: cover;
}

.hero-left-heading {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: bold;
    /* font-size: 2.35vw; */
    font-size: 47px;
    color: #FFFFFF;

}

.hero-left-para {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-size: 20px;
    /* font-size: 1.388vw; */
    color: #FFFFFF;

}

.hero-left-CTA {
    border: none;
    outline: none;
    background-color: #212626;
    color: #FFFFFF;
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 10px 20px 10px 20px;
    font-size: 16px;
    /* font-size: 1vw; */
    border-radius: 3px;
}

.hero-left-CTA:hover {
    background-color: #707070;
    border-radius: 3px;
}

.hero-left-avatar {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    /* background-color: red; */
}

.hero-left-avatar-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100px;

}

.hero-left-review-text {
    font-family: 'IBM Plex Sans', sans-serif;
    color: white;
    margin: 0px;
    /* font-size: 16px; */
    font-size: 16px;
}

.hero-right {
    height: 100vh;
}

.hero-right-img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    /* z-index: 0; */
}

@media screen and (max-width:1073px) {
    .android-buuton {
        margin-top: 10px;
    }
}

@media screen and (max-width:990px) {

    .hero-left-heading {
        font-size: 47px;
    }

    .hero-left-para {
        font-size: 20px;
    }

    .hero-left-CTA {
        font-size: 16px;
    }

    .hero-left-review-text {
        font-size: 16px;
    }

    .hero-left {
        height: 100%;
    }

    .hero-right {
        height: 100%;
    }
}

@media screen and (max-width:500px) {
    .hero-left-heading {
        font-size: 40px;
    }

    .hero-left-para {
        font-size: 16px;
    }
}

@media screen and (max-width:530px) {
    .hero-left {
        height: auto;
    }

    .hero-left-avatar {
        /* height: 100px; */
        /* width: 100px; */
        /* height: 60px; */
        /* width: 60px; */
    }
}