.about-page {
    /* height: 100vh; */
    background-color: #F2F2F2;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    background-image: url('../../../../assests/herobg.png');
    background-repeat: no-repeat;
    background-size: cover;
}
/*  */

.about-page-top {
    width: 40%;
    padding-top: 180px;
    padding-bottom: 180px;

}

.about-page-heading {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    /* font-size: 2.35vw; */
    font-size: 40px;
    color: #000000;
}

.about-page-para {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 28px;
    color: #565D5D;
}

.about-page-bottom {
    width: 70%;
}

.about-page-bottom-views {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.about-page-bottom-img-view {
    height: 60px;
    width: 60px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black
}

.about-page-bottom-img-view-img {
    object-fit: contain;
    height: 60%;
    width: 60%;
}

.about-page-bottom-subheading {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: 26px;
}

.about-page-bottom-para {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    text-align: center;

}


@media screen and (max-width:1100px) {
    .about-page-top {
        width: 60%;
    }

    .about-page {
        height: auto;
    }
}

@media screen and (max-width:770px) {
    .about-page-heading {
        font-size: 37px;
    }

    .about-page-para {
        font-size: 25px;

    }
}
@media screen and (max-width:767px) {
    .about-page-top {
        width: 80%;
        padding-top: 90px;
        padding-bottom: 90px;
    
    }
}

@media screen and (max-width:500px) {
    .about-page-heading {
        font-size: 26px;
    }
    .about-page-para {
        font-size: 20px;
    
    }
    .about-page-bottom {
        width: 90%;
    }

    .about-page-top {
        width: 100%;
    }
}